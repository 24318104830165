<template>
  <v-container fill-height justify-center>
    <v-dialog max-width="400" v-model="showOtpEmailDialog">
      <v-card>
        <v-card-title>
          {{ $t("modal.otpEmail.title") }}
        </v-card-title>
        <v-card-text v-html="$t('modal.otpEmail.description')"></v-card-text>
        <v-form ref="otpEmailDialog" v-model="otpEmailValid">
          <v-card-actions>
            <v-text-field
              type="email"
              :placeholder="$t('modal.otpEmail.placeholder')"
              v-model="otpEmail"
              required
              :rules="emailRules"
            ></v-text-field>
          </v-card-actions>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showOtpEmailDialog = false">{{
            $t("modal.cancel")
          }}</v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!otpEmailValid"
            @click="openDoor({ otpEmail }), (showOtpEmailDialog = false)"
            >{{ $t("modal.continue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout style="width: 100%" row wrap>
      <v-flex xs12>
        <div
          v-if="onekeyappRequired"
          class="display-1 text-center"
          v-html="$t('app.openOneKey')"
        ></div>
        <div
          v-else
          class="display-1 text-center"
          v-html="$t('app.yourRoom', { number: authResult.room })"
        ></div>
      </v-flex>
      <v-flex xs12>
        <v-layout justify-center>
          <v-card color="transparent" flat style="max-width: 220px">
            <form name="param" method="GET">
              <v-btn
                v-if="onekeyappRequired"
                color="primary"
                fab
                style="width: 200px; height: 200px"
                data-v-step="key_0"
                @click="showOtpEmailDialog = true"
              >
                <v-layout align-center justify-center>
                  <v-icon size="160px">mdi-open-in-app</v-icon>
                </v-layout>
              </v-btn>
              <v-btn
                v-else
                color="primary"
                fab
                style="width: 200px; height: 200px"
                data-v-step="key_0"
                @click="openDoor()"
              >
                <v-layout align-center justify-center>
                  <v-icon v-if="onekeyappRequired" size="160px"
                    >mdi-open-in-app</v-icon
                  >
                  <v-icon size="160px">mdi-lock-open</v-icon>
                </v-layout>
              </v-btn>
            </form>
          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MobileDetect from "mobile-detect";

export default {
  data() {
    return {
      isIos: false,
      demoMesserschmitt: {
        mainAndroidLink:
          "intent:#Intent;action=com.messerschmitt.launchfrombrowser;package=com.messerschmitt.demoatvision;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;",
        parm1: "S.param1=",
        parm2: "S.param2=",
        endP: "end;",
        token: "test_device_identifier",
        key:
          "eyJ2YWxpZCI6MSwicmVhc29uIjotMSwic2MiOjg1LCJybSI6W3sibCI6IjEiLCJwIjoxfV0sInNhIjpbMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1XSwiZHQiOlsxNDAsMiwyNTMsMSwyNTQsMTk1LDk4LDQyLDYwLDE1NCwyMzcsODMsMTI0LDczLDEwOCwxMzksNDEsMjM2LDE4NCwxMzEsODIsMzIsMTYsMTM1LDE1MCw5NSw4LDI2LDc0LDIxMSw5LDIzMSwxMzEsMTc3LDEyNCwxMjIsMTM1LDIyNSw3NCwxOTldLCJkYSI6IjIwMjAtMDQtMzBUMTg6MDArMDA6MDAiLCJnIyI6IiJ9",
      },
      onekeyappRequiredVendors: ["assa_abloy"],
      showOtpEmailDialog: false,
      otpEmail: "",
      otpEmailValid: false,
      emailRules: [
        (v) => !!v || this.$t("general.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("general.emailNotValid"),
      ],
    };
  },
  mounted() {
    const md = new MobileDetect(window.navigator.userAgent);
    this.isIos = md.os() === "iOS";
  },
  computed: {
    deepLink() {
      const { mode } = this.$store.state.session;
      const {
        token,
        key,
        mainAndroidLink,
        parm1,
        parm2,
        endP,
      } = this.demoMesserschmitt;
      if (mode === "demo") {
        if (this.isIos) {
          return `com.messerschmitt.demoatvision://launchfrombrowser?token=${token}&key=${key}&origin_url=${btoa(
            window.location.href
          )}`;
        } else {
          return `${mainAndroidLink}${parm1}${token};${parm2}${key};${endP}`;
        }
      } else {
        const { onekeySession = { link: "" } } = this.$store.state.session;
        return link;
      }
    },
    onekeyappRequired() {
      return true;
      return this.onekeyappRequiredVendors.find((o) => o === this.vendor);
    },
    ...mapState({
      authResult: ({ session }) => session.authResult,
      vendor: ({ session }) => session.settings?.onekey?.vendor,
    }),
  },
  methods: {
    ...mapMutations({ setOtpEmail: "session/SET_OTP_EMAIL" }),
    ...mapActions("onekey", ["openDoor"]),
  },
};
</script>
